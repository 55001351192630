import axiosIns from "@/libs/axios";

const store = async (data) => {
  return await axiosIns.post('transferencias', data)
}

const findOnePdf = async (id) => {
  return await axiosIns.get(`transferencias/pdf/${id}`)
}

const get = async (sede_id) => {
  return await axiosIns.get(`transferencias?sede_id=${sede_id}`)
}

const getSedes = async () => {
  return await axiosIns.get('sedes')
}

const getRecepciones = async (sede_id) => {
  return await axiosIns.get(`recepciones?sede_objetivo_id=${sede_id}`)
}

const storeRecepcion = async (data) => {
  return await axiosIns.post('recepciones', data)
}

const findOnePdfRecepcion = async (id) => {
  return await axiosIns.get(`recepciones/pdf/${id}`)
}

export default {
  store,
  findOnePdf,
  get,
  getSedes,
  getRecepciones,
  storeRecepcion,
  findOnePdfRecepcion,
}
